import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Header from '../components/header/header'
import PageContainer from '../components/pages/page-container'
import Footer from '../components/layout/footer'
import CookieBox from '../components/cookie/cookie-box'
import '../components/pages/press.scss'

const PressPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | PRESS PAGE</title>
        <body className="press" />
      </Helmet>
      <div className="presspage">
        <Header darkTheme />
        <CookieBox />
        <PageContainer>
          <h3>Press page</h3>
          <Link
            download
            href="https://twinpeak.cz/darc/zip/DARCMONO_PRESS_KIT_08-22.zip"
          >
            <button>Download press kit</button>
          </Link>
        </PageContainer>
        <Footer />
      </div>
    </>
  )
}

export default PressPage
